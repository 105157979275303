'use client'

import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx/lite'
import { Resources } from 'i18next'
import Cookies from 'js-cookie'
import Image from 'next/image'
import Link from 'next/link'
import { usePathname } from 'next/navigation'

import { Fragment, useEffect, useRef, useState } from 'react'

import Container from '@/components/layout/container'
import { currencies, Currency, Lang, locales, replaceLang } from '@/i18n'
import { ChevronDownIcon } from '@heroicons/react/16/solid'

interface NavigationProps {
  lang: Lang
  defaultCurrency: Currency
  resources: Resources
}

const navigation = [
  { name: 'Navigation.Action.Offers', href: '/offers' },
  { name: 'Navigation.Action.HowToInstall', href: 'https://docs.esimfly.app/{{lng}}/' },
  { name: 'Navigation.Action.HowToPay', href: '/payment-info' },
  { name: 'Navigation.Action.PaymentSecurity', href: '/payment-security' },
  { name: 'Navigation.Action.AboutUs', href: '/about-us' },
]

function checkIsMobileRedirect() {
  return typeof window !== 'undefined' && window.location.search.includes('mobile')
}

const Navigation = ({ lang, defaultCurrency, resources }: NavigationProps) => {
  const pathname = usePathname()
  const [currency, setCurrency] = useState<Currency>(defaultCurrency)
  const [hide, setHide] = useState(false)

  useEffect(() => {
    if (checkIsMobileRedirect()) {
      setHide(true)
    }
  }, [])

  return (
    <Disclosure as='nav' className={clsx('bg-blue-950 min-h-14 md:min-h-20', hide && 'hidden')}>
      {({ open }) => (
        <>
          <Container className='py-2 sm:py-3 lg:py-5'>
            <div className='relative flex h-10 items-center justify-between'>
              <div className='absolute inset-y-0 left-0 flex items-center lg:hidden'>
                {/* Mobile menu button*/}
                <DisclosureButton className='relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                  <span className='absolute -inset-0.5' />
                  <span className='sr-only'>{resources['Navigation.Action.OpenMenu']}</span>
                  {open ? <XMarkIcon className='block h-6 w-6' aria-hidden='true' /> : <Bars3Icon className='block h-6 w-6' aria-hidden='true' />}
                </DisclosureButton>
              </div>
              <div className='flex flex-1 items-center justify-center md:items-stretch md:justify-start'>
                <Link prefetch={false} href={`/${lang}/offers`} className='ml-14 lg:ml-0 flex flex-shrink-0 items-center'>
                  <Image className='!h-8 !w-14' height={32} width={170} src='/logo.png' alt='eSIMFly' />
                  <div style={{ fontSize: '16px' }}>
                    <span style={{ color: '#F3603C', fontWeight: 'bolder' }}>F</span>
                    <span style={{ color: '#F3603C', fontWeight: 'bolder' }}>ly</span>
                  </div>
                </Link>

                <div className='flex flex-1 justify-end lg:justify-start'>
                  <div className='flex space-x-4'>
                    {/*Language Switcher*/}
                    <Menu as='div' className='relative ml-3'>
                      <div>
                        <MenuButton as={Fragment}>
                          {({ open, hover }) => (
                            <button
                              className={clsx(
                                `px-4 py-2 rounded-full justify-center items-center gap-2 inline-flex text-sm font-normal text-neutral-50 border border-transparent`,
                                open && 'text-gray-400 border-gray-400',
                                !open && hover && 'border-b border-white',
                              )}
                              type='button'
                              aria-haspopup='menu'
                              aria-expanded={open}
                            >
                              <span className='sr-only'>{resources['Navigation.Action.ChangeLanguage']}</span>
                              {resources[`Navigation.Text.${lang}`]}
                              <ChevronDownIcon className='size-4' />
                            </button>
                          )}
                        </MenuButton>
                      </div>
                      <Transition
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                      >
                        <MenuItems className='z-[51] absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                          {locales.map(locale => {
                            return (
                              <MenuItem
                                as={Link}
                                href={replaceLang({
                                  pathname,
                                  locale,
                                })}
                                prefetch={false}
                                onClick={() => {
                                  Cookies.set('NEXT_LOCALE', locale)
                                }}
                                key={locale}
                              >
                                {({ focus }) => <span className={clsx(focus ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>{resources[`Navigation.Text.${locale}`]}</span>}
                              </MenuItem>
                            )
                          })}
                        </MenuItems>
                      </Transition>
                    </Menu>

                    {/*Currency Switcher*/}
                    <Menu as='div' className='relative ml-3'>
                      <div>
                        <MenuButton as={Fragment}>
                          {({ open, hover }) => (
                            <button
                              className={clsx(
                                `px-4 py-2 rounded-full justify-center items-center gap-2 inline-flex text-sm font-normal text-neutral-50 border border-transparent`,
                                open && 'text-gray-400 border-gray-400',
                                !open && hover && 'border-b border-white',
                              )}
                              type='button'
                              aria-haspopup='menu'
                              aria-expanded={open}
                            >
                              <span className='sr-only'>{resources['Navigation.Action.ChangeCurrency']}</span>
                              {currency && <span>{currency}</span>}
                              <ChevronDownIcon className='size-4' />
                            </button>
                          )}
                        </MenuButton>
                      </div>
                      <Transition
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                      >
                        <MenuItems className='z-[51] absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                          {currencies.map(c => {
                            return (
                              <MenuItem
                                as={'div'}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  Cookies.set('NEXT_CURRENCY', c)
                                  setCurrency(c)
                                  window.location.reload()
                                }}
                                key={c}
                              >
                                {({ focus }) => <span className={clsx(focus ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>{c}</span>}
                              </MenuItem>
                            )
                          })}
                        </MenuItems>
                      </Transition>
                    </Menu>
                  </div>
                </div>
                <div className='hidden md:ml-6 lg:block'>
                  <div className='flex space-x-4'>
                    {navigation.map(item => {
                      if (item.href.startsWith('http')) {
                        const localizedHref = item.href.replace('{{lng}}', lang)
                        return (
                          <a
                            className={clsx(`px-4 py-2 rounded-full justify-center items-center gap-2 inline-flex text-sm font-normal text-neutral-50 truncate`, `hover:border-b hover:border-white`)}
                            href={localizedHref}
                            target='_blank'
                            key={item.name}
                          >
                            {resources[item.name]}
                          </a>
                        )
                      }
                      const active = item.href === pathname.substring(3)
                      return (
                        <Link
                          className={clsx(
                            `px-4 py-2 rounded-full justify-center items-center gap-2 inline-flex text-sm font-normal text-neutral-50 truncate`,
                            `hover:border-b hover:border-white`,
                            active && `text-red-500`,
                          )}
                          prefetch={false}
                          href={`/${lang}${item.href}`}
                          aria-current={active ? 'page' : undefined}
                          key={item.name}
                        >
                          {resources[item.name]}
                        </Link>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Container>

          <DisclosurePanel className='lg:hidden'>
            <div className='space-y-1 px-2 pb-3 pt-2'>
              {navigation.map(item => {
                if (item.href.startsWith('http')) {
                  const localizedHref = item.href.replace('{{lng}}', lang)
                  return (
                    <a className={clsx(`block rounded-md px-3 py-2 text-base font-medium`, `text-gray-300 hover:bg-gray-700 hover:text-white`)} href={localizedHref} target='_blank' key={item.name}>
                      {resources[item.name]}
                    </a>
                  )
                }

                const active = item.href === pathname.substring(3)
                return (
                  <DisclosureButton
                    key={item.name}
                    as='a'
                    href={`/${lang}${item.href}`}
                    className={clsx(active ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'block rounded-md px-3 py-2 text-base font-medium')}
                    aria-current={active ? 'page' : undefined}
                  >
                    {resources[item.name]}
                  </DisclosureButton>
                )
              })}
            </div>
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  )
}

export default Navigation
