import { createInstance, i18n, Resource } from 'i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import path from 'path'
import { initReactI18next } from 'react-i18next/initReactI18next'
import { i18nConfig, Lang } from './'

export default async function initTranslations(lang: Lang, namespaces: string[], i18nInstance?: i18n, resources?: Resource) {
  i18nInstance = i18nInstance || createInstance()

  i18nInstance.use(initReactI18next)

  if (!resources) {
    i18nInstance.use(
      resourcesToBackend((lang: Lang, namespace: string) => {
        if (typeof window === 'undefined') {
          return import('fs').then(fs => JSON.parse(fs.readFileSync(path.resolve(`./src/locales/${lang}/${namespace}.json`), 'utf8')))
        } else return import(`@/locales/${lang}/${namespace}.json`)
      }),
    )
  }

  await i18nInstance.init({
    lng: lang,
    resources,
    fallbackLng: i18nConfig.defaultLocale,
    supportedLngs: i18nConfig.locales,
    defaultNS: namespaces[0],
    fallbackNS: namespaces[0],
    ns: namespaces,
    preload: resources ? [] : i18nConfig.locales,
  })
  return {
    i18n: i18nInstance,
    resources: i18nInstance.services.resourceStore.data,
    t: i18nInstance.t,
  }
}
