'use client'

import { ReactNode } from 'react'
import { I18nextProvider } from 'react-i18next'
import { createInstance, Resource } from 'i18next'

import { Lang } from '@/i18n/types'

import initTranslations from './init-translations'

interface TranslationsProviderProps {
  children: ReactNode
  lang: Lang
  namespaces: string[]
  resources?: Resource
}
export default function TranslationsProvider({ children, lang, namespaces, resources }: TranslationsProviderProps) {
  const i18n = createInstance()

  initTranslations(lang, namespaces, i18n, resources)

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}
